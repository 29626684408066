<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-22 18:14:02
 * @LastEditTime: 2023-06-26 10:02
 * @Descripttion: 个人中心
-->
<style lang="scss" scoped>
.personal-center {
  @include innerPage;
  @include pageHead(-42px);

  .page-inner {
    width: 100%;
    height: calc(100% - 192px);
    margin-top: 30px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    @include flexBox(flex-start, flex-start);

    .calling-card {
      width: 330px;
      height: 391px;
      background: #4e329f;
      border-radius: 30px;
      margin-bottom: 36px;
      flex-direction: column;
      @include flexBox(center);

      &--wrapper {
        width: 330px;
        height: 464px;
        position: sticky;
        top: 0;
        left: 0;

        .btn-group {
          width: 100%;
          @include flexBox(center);
        }
      }

      .avatar-uploader {
        width: 145px;
        height: 145px;
        border-radius: 50%;
        border: 2px solid #e8eaf3;
        position: relative;

        .camera {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 2px solid #ededed;
          background: #fff;
          position: absolute;
          bottom: 0;
          right: 8px;
          text-align: center;
          line-height: 32px;
          color: #cbcbcb;
          font-size: 18px;
        }

        .avatar,
        .el-upload {
          width: 100%;
          height: 100%;
        }

        .avatar {
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .name-edit {
        height: 46px;
        margin-top: 30px;
        @include flexBox(center);

        input {
          max-width: 200px;
          min-width: 24px;
          height: 46px;
          line-height: 46px;
          border: none;
          background: transparent;
          color: #e8eaf3;
          text-align: center;
          font-size: 24px;
          font-weight: bold;
          outline: none;
          padding: 0 16px;
        }

        .el-button {
          padding-left: 0;
          margin-right: -74px;
        }
      }

      .phone {
        font-size: 18px;
        color: #e8eaf3;
        margin-top: 18px;
      }
    }

    .info-wrapper {
      width: 752px;
      height: 668px;
      border-radius: 30px;
      background: #fff;
      margin-left: 30px;
      box-sizing: border-box;
      padding: 24px 68px;

      .info-item {
        height: 126px;
        border-bottom: 1px solid #e8eaf3;
        flex-direction: column;
        @include flexBox(center, flex-start);

        &:last-child {
          border-bottom: none;
        }

        .label {
          color: #262626;
          line-height: 24px;
        }

        .val {
          width: 100%;
          line-height: 24px;
          margin-top: 10px;

          span {
            font-size: 16px;
            color: #262626;
          }

          @include flexBox(space-between);
        }
      }
    }
  }
}

.dialog-container {
  box-sizing: border-box;
  padding: 34px 0 48px;

  .tips {
    width: 100%;
    height: 46px;
    line-height: 46px;
    margin-bottom: 20px;

    .label {
      display: inline-block;
      width: 110px;
      color: #1b1b1b;
    }

    .phone {
      color: #151515;
      font-size: 16px;
    }
  }

  .el-form-item {
    width: 100%;

    &.code {
      ::v-deep {
        .el-input__inner {
          padding-right: 130px;
        }
      }

      .el-button {
        position: absolute;
        right: 0;
        top: 11px;
      }
    }
  }

  .el-form-item:last-child {
    margin-bottom: 0;
  }
}
</style>

<template>
  <section class="personal-center">
    <div class="page-head">
      <img class="bitmap" src="@assets/images/bitmap-head.png" alt />
      <div class="head-wrapper">
        <breadcrumb />
        <el-button type="custom_primary" size="medium" @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
    <div class="page-inner">
      <div class="calling-card--wrapper">
        <div class="calling-card">
          <el-upload class="avatar-uploader" :action="$upload.imgAction" :headers="$upload.header" :show-file-list="false"
            :accept="$upload.imgAccept" :before-upload="$beforImgUpload" :on-success="handleAvatarSuccess">
            <el-avatar fit="cover" :size="145" :src="formatfile(userData.teuse_image)" class="avatar">
              <img src="@assets/images/empty_avatar.png" />
            </el-avatar>
            <p class="camera iconfont">&#xe621;</p>
          </el-upload>
          <div class="name-edit">
            <input
              :style="{ width: `${userData.teuse_name && userData.teuse_name.length > 5 && 120 || userData.teuse_name && userData.teuse_name.length * 24 || 24}px` }"
              v-model="userData.teuse_name" ref="nameIpt" type="text" maxlength="20" :readonly="!nameEdit"
              @blur="nameEdit = false; setUserInfo()"
              @input="userData.teuse_name = userData.teuse_name.replace(/\s+/g, '')" />
            <el-button type="text" class="success" @mousedown.native="iptFocus">
              <i class="iconfont">&#xe61e;</i>修改
            </el-button>
          </div>
          <p class="phone pf">AN：{{ userData.teuse_account }}</p>
        </div>
        <div class="btn-group">
          <el-button type="custom_primary" size="small" @click="pwdDialog = true">修改密码</el-button>
          <el-button type="custom_info" size="small" @click="loginOut">退出登录</el-button>
        </div>
      </div>
      <ul class="info-wrapper">
        <li class="info-item">
          <h5 class="label bold">手机号</h5>
          <p class="val">
            <span class="pf">{{ userData.teuse_phone }}</span>
            <el-button type="text" class="success" @click="phoneDialog = true">
              <i class="iconfont">&#xe61e;</i>修改
            </el-button>
          </p>
        </li>
        <li class="info-item">
          <h5 class="label bold">职称</h5>
          <p class="val">
            <span class="pf">{{ userData.teuse_lev_title || '-' }}</span>
          </p>
        </li>
        <li class="info-item">
          <h5 class="label bold">所属学校</h5>
          <p class="val">
            <span class="pf">{{ userData.scsch_name || '-' }}</span>
          </p>
        </li>
        <li class="info-item">
          <h5 class="label bold">教师身份</h5>
          <p class="val">
            <span class="pf">{{ userData.teacher_identity || '-' }}</span>
          </p>
        </li>
        <li class="info-item">
          <h5 class="label bold">教学科目</h5>
          <p class="val">
            <span class="pf">{{ userData.teacher_subject || '-' }}</span>
          </p>
        </li>
      </ul>
    </div>
    <el-dialog title="修改密码" width="450px" :show-close="false" :visible.sync="pwdDialog"
      @close=" hideDialog('passwordForm')">
      <div class="dialog-container">
        <p class="tips">
          <span class="label">当前手机号</span>
          <span class="phone pf">{{ userData.teuse_phone }}</span>
        </p>
        <el-form ref="passwordForm" :model="passwordForm" :rules="passwordRules">
          <!-- <el-form-item prop="code" class="code">
            <el-input v-model.trim="passwordForm.code" ref="codeIpt" type="text" maxlength="100" placeholder="请输入验证码" />
            <el-button type="text" class="primary" @click.stop="getCode" :disabled="codeSend">
              {{ codeSend && `${codeCountdown}s后可重新获取` || '获取验证码' }}
            </el-button>
          </el-form-item> -->
          <el-form-item prop="teuse_password">
            <el-input v-model.trim="passwordForm.teuse_password" type="password" maxlength="16"
              placeholder="请输入原始密码(8~16位)" />
          </el-form-item>
          <el-form-item prop="newPassword">
            <el-input v-model.trim="passwordForm.newPassword" type="password" maxlength="16"
              placeholder="请设置新密码(8~16位)" />
          </el-form-item>
          <el-form-item prop="againPassword">
            <el-input v-model.trim="passwordForm.againPassword" type="password" maxlength="16"
              placeholder="请再次确认新密码(8~16位)" />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="custom_info" size="small" @click=" hideDialog('passwordForm')">取 消</el-button>
        <el-button type="custom_primary" size="small" @click="setPwd" :disabled="btnload" v-loading="btnload">确
          定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改手机" width="450px" :show-close="changePhoneStep == 1" :visible.sync="phoneDialog"
      @close="hideDialog('phoneForm')">
      <div class="dialog-container">
        <p class="tips" v-if="changePhoneStep == 1">
          <span class="label">当前手机号</span>
          <span class="phone pf">{{ userData.teuse_phone }}</span>
        </p>
        <el-form ref="phoneForm" :model="phoneForm" :rules="phoneRules">
          <el-form-item v-show="changePhoneStep == 2" prop="phone">
            <el-input v-model.trim="phoneForm.phone" type="text" maxlength="11" placeholder="请输入新手机号码"
              @input="phoneForm.phone = iptInit(phoneForm.phone)" />
          </el-form-item>
          <el-form-item prop="code" class="code">
            <el-input v-model.trim="phoneForm.code" ref="codeIpt" type="text" maxlength="100" placeholder="请输入验证码" />
            <el-button type="text" class="primary" @click.stop="getCode" :disabled="codeSend">
              {{ codeSend && `${codeCountdown}s后可重新获取` || '获取验证码' }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <template v-if="changePhoneStep == 1">
          <el-button type="custom_primary" size="small" @click="checkPhone" :disabled="!phoneForm.code">下一步</el-button>
        </template>
        <template v-if="changePhoneStep == 2">
          <el-button type="custom_info" size="small" @click="hideDialog('phoneForm')">取 消</el-button>
          <el-button type="custom_primary" size="small" @click="changePhone" :disabled="btnload" v-loading="btnload">确
            定</el-button>
        </template>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { $changePwd, $setUserInfo, $checkPhone, $changePhone } from "@api/user";
import { $getCode } from "@api/common";
import { mapActions, mapState } from "vuex";
import { formatFile, iptInteger } from "@utils";
import { validPassword, validPhone } from "@utils/validate";
export default {
  name: "home_personalCenter",
  computed: {
    ...mapState("user", ["userInfo"]),
    formatfile() {
      return function (url) {
        return formatFile(url)
      }
    },
    iptInit() {
      return function (val) {
        return iptInteger(val, '')
      }
    },
  },
  watch: {
    userInfo: {
      handler(newVal) {
        this.userData = { ...newVal };
      },
      immediate: true,
    },
  },
  data() {
    // 电话号码校验
    let validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      } else if (!validPhone(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    // 密码校验
    let validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (value.length < 8 || value.legnth > 16) {
        callback(new Error("请输入8~16位密码"));
      } else if (!validPassword(value)) {
        callback(new Error("密码包含数字、大写字母、小写字母、特殊字符"));
      } else {
        callback();
      }
    };
    // 再次输入密码校验
    let validatePass2 = (rule, value, callback) => {
      if (!value && this.passwordForm.newPassword) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passwordForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      userData: {},
      nameEdit: false, // 用户名修改
      pwdDialog: false, // 显示修改密码弹窗
      passwordForm: {}, // 修改密码表单
      phoneDialog: false, // 显示手机号修改弹窗
      changePhoneStep: 1, // 手机号修改步骤
      phoneForm: {}, // 修改手机号表单
      /* 密码表单校验规则 */
      passwordRules: {
        // code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        teuse_password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        newPassword: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        againPassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      btnload: false,
      /* 手机表单校验规则 */
      phoneRules: {
        code: [{ required: true, message: "请输入验证码", trigger: "change" }],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
      },
      codeSend: false, // 验证码发送
      codeCountdown: 60, // 重新发送验证码倒计时
      currentInterval: null, // 当前倒计时实例
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    /** 修改密码 */
    async setPwd() {
      this.$refs.passwordForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let params = {
            teuse_phone: this.userInfo.teuse_phone,
            teuse_password: this.passwordForm.teuse_password,
            newPassword: this.passwordForm.newPassword,
            code: this.passwordForm.code,
          };
          let res = await $changePwd(params);
          this.btnload = false;
          // 密码修改成功后跳转重新登录
          if (res) {
            this.hideDialog("passwordForm");
            this.$message.success("密码修改成功,即将跳转重新登录");
            //删除强制修改密码
            sessionStorage.removeItem("updatePwd");
            setTimeout(() => {
              sessionStorage.clear();
              this.$router.replace({ name: "LOGIN" });
            }, 300);
          }
        }
      });
    },
    /** 修改教师信息 */
    async setUserInfo() {
      let { userData, userInfo } = this;
      if (!this.userData.teuse_name) {
        this.userData.teuse_name = this.userInfo.teuse_name;
        return;
      }
      let params = { teuse_name: userData.teuse_name };
      if (userInfo.teuse_image != userData.teuse_image)
        params.teuse_image = userData.teuse_image;
      let res = await $setUserInfo(params);
      this.getUserInfo();
    },
    /** 校验原手机号 */
    async checkPhone() {
      let res = await $checkPhone(
        this.phoneForm.code,
        this.userInfo.teuse_phone
      );
      if (res) {
        this.changePhoneStep = 2;
        this.phoneForm.code = "";
        this.codeSend=false
      }
    },
    /** 绑定新手机号 */
    changePhone() {
      this.$refs.phoneForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let { code, phone } = this.phoneForm;
          let res = await $changePhone(code, phone);
          this.btnload = false;
          if (res) {
            this.hideDialog("phoneForm");
            this.$message.success("手机号修改成功");
            this.getUserInfo();
          }
        }
      });
    },
    iptFocus(e) {
      this.nameEdit = true;
      this.$refs.nameIpt.focus();
      this.$nextTick(() => {
        this.$refs.nameIpt.setSelectionRange(this.userData.teuse_name.length, this.userData.teuse_name.length)
      })
      e.preventDefault();
    },
    /**
     * 文件上传成功
     * @param {*} res 响应结果
     * @param {file} file 返回文件
     */
    handleAvatarSuccess({ data, msg }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.userData.teuse_image = data.address;
      /* 操作修改用户信息 */
      this.setUserInfo();
    },
    /** 关闭弹窗 */
    hideDialog(ref) {
      this.pwdDialog = false;
      this.phoneDialog = false;
      this.changePhoneStep = 1;
      this.resetForm(ref);
      this[ref] = {};
      clearInterval(this.currentInterval);
      this.codeCountdown = 60;
      this.codeSend = false;
    },
    /** 重置表单 */
    resetForm(ref) {
      this.$refs[ref].resetFields();
    },
    /** 获取验证码 */
    async getCode() {
      let phone;
      if (this.changePhoneStep == 2) {
        this.$refs.phoneForm.validateField('phone', error => {
          if (!error) phone = this.phoneForm.phone;
        });
      } else {
        phone = this.userData.teuse_phone;
      }
      if (phone) {
        let res = await $getCode(phone);
        if (res) {
          this.codeSend = true;
          this.timeCountdown();
          this.$message.success('验证码发送成功，请注意查收');
        }
      }
    },
    /** 时间倒计时 */
    timeCountdown() {
      clearInterval(this.currentInterval);
      this.currentInterval = setInterval(() => {
        if (this.codeCountdown == 1) {
          this.codeCountdown = 60;
          this.codeSend = false;
          clearInterval(this.currentInterval);
        } else {
          this.codeCountdown -= 1
        }
      }, 1000);
    },
    /** 退出登录 */
    loginOut() {
      sessionStorage.clear();
      this.$router.replace({ name: "LOGIN" });
    }
  },
};
</script>